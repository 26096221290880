.location-picker {
  margin: $gutter-1 0;

  .col-auto {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      display: flex;
      flex: 0;
      padding-right: $gutter-05;
    }
  }

  select {
    background-color: $color-gray-1;
    border: none;

    &:disabled {
      background-color: $color-gray-1;
      opacity: 0.5;
    }
  }
}

.modal-content .location-picker {
  select {
    background-color: $color-white;
  }
}
