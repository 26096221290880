.ReactTags__tags {
  background: white;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: flex;
  flex-direction: row;
  padding: .375rem .75rem 0 .75rem;
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  //border-radius: 2px;
  display: flex;
  flex: 1 1;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  //height: 31px;
  //margin: 0;
  //font-size: 12px;
  border: none;
  //min-width: 150px;
  display: flex;
  flex: 1;
  outline: none;
  margin-bottom: .375rem;
  color: #212529;
}

.ReactTags__editInput {
  //border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.ReactTags__selected span.ReactTags__tag {
  background: $color-gray-4;
  border-radius: .25rem;
  margin-right: .5rem;
  display: inline-block;
  padding-left: .75rem;
  margin-bottom: .375rem;
  color: $color-white;
  flex: 0;
  white-space: nowrap;
}
.ReactTags__selected button.ReactTags__remove {
  cursor: pointer;
  border: none;
  background: none;
  color: $color-white;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 3;
}

.ReactTags__suggestions ul {
  background: $color-white;
  list-style: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
  border: 1px solid #86b7fe;
  margin: 0;
  padding: 0;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ced4da;
  padding: 5px 10px;
  margin: 0;

  &:last-child {
    border-bottom: none;
  }
}

.ReactTags__suggestions li mark, .ReactTags__suggestions li span {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: $color-green;
  color: $color-white;
  cursor: pointer;
}
