
// Variables
$color-black: #000000;
$color-white: #FFFFFF;
$color-green: #77B92B;
$color-green-2: #5A9616;
$color-orange: #CC8B32;
$color-oranger: #FFA500;
$color-red: #E30512;
$color-red-2: #C70613;

$color-gray-1: #F2F2F2;
$color-gray-2: #E4E4E4;
$color-gray-3: #D8D8D8;
$color-gray-4: #616161;
$color-gray-5: #434343;
$color-gray-6: #323232;
$color-gray-7: #212121;
$color-gray-8: #1A1A1A;

$aside-width: 256px;

$gutter-01: 0.1rem;
$gutter-025: 0.25rem;
$gutter-05: 0.5rem;
$gutter-1: 1rem;
$gutter-2: 1.5rem;
$gutter-3: 2rem;
$gutter-4: 2.5rem;

$border-radius-025: 3px;
$border-radius-05: 6px;
$border-radius-075: 9px;
$border-radius-1: 12px;

// Body
body {
  margin-left: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

aside.in + .container-fluid {
  padding-left: $aside-width + 15px;
}

.page-header {
  min-height: 68px;
}

.modal {
  background-color: rgba(0,0,0,0.4);

  .modal-content {
    background-color: $color-gray-1;
    border: none;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: $gutter-05;

    h5 {
      font-weight: 700;
      font-size: 18px;
    }

    .modal-header-options {
      display: flex;
      margin: 0 0 0 auto;
    }

    .btn-close {
      margin-left: 0.5rem;
      margin-top: 0.15rem;
    }
  }

  .modal-body {
    padding-top: $gutter-05;
  }

  .modal-footer {
    border-top: none;
  }
}

.btn {
  &.btn-link {
    text-decoration: none;
  }

  &.btn-primary {
    background-color: $color-green;
    border: 1px solid $color-green;

    font-weight: 700;
    font-size: 14px;

    &:hover {
      background-color: $color-green-2;
      border: 1px solid $color-green-2;
      cursor: pointer;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border: 1px solid $color-red;

    font-weight: 700;
    font-size: 14px;

    &:hover {
      background-color: $color-red-2;
      border: 1px solid $color-red-2;
      cursor: pointer;
    }
  }

  &.btn-load-more {
    background-color: $color-gray-2;
    border-color: $color-gray-2;
    color: var(--bs-body-color);

    &:hover {
      background-color: $color-gray-3;
      border-color: $color-gray-3;
    }
  }
}

.btn-full {
  width: 100%;
}

.btn-export,
.btn-import {
  background: $color-gray-5;
  color: $color-white;

  &:hover {
    background: $color-gray-4;
    color: $color-white;
  }
}

.qr {
  margin-bottom: $gutter-1;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235A9616'/%3e%3c/svg%3e");
}

.form-check-input:focus {
  border-color: $color-green-2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($color-green-2, .25);
}

.form-check-input:checked {
  background-color: $color-green;
  border-color: $color-green-2;
}

.search-bar {
  margin-top: 4px;
  margin-bottom: 10px;
}

label {
  font-weight: 700;
  font-size: 14px;
  padding-left: 0 !important;
}

button.dropdown-item:active {
  background-color: $color-green;
}

.row-message {
  margin-top: $gutter-1;
}

[class*=' flag:'], [class^='flag:'] {
  margin-top: -2px;
}

// Modules
@import "Aside";
@import "Calendar";
@import "ComponentDatePicker";
@import "ComponentEmployee";
@import "ComponentHotelPicker";
@import "ComponentHotelRoomOverview";
@import "ComponentLocationPicker";
@import "ComponentTasks";
@import "ComponentWeek";
@import "ComponentHotelWeek";
@import "ComponentWeekBudget";
@import "ComponentWeekContext";
@import "ComponentWeekCosts";
@import "ComponentWeekForecast";
@import "ComponentWeekDemand";
@import "ComponentWeekSupply";
@import "ComponentWeekTemps";
@import "ComponentModalFindRooms";
@import "Login";
@import "Tooltip";
@import "ViewBedplanner";
@import "ViewCheckInOut";
@import "ViewCostsOverview";
@import "ViewHousing";
@import "ViewPlanboard";
@import "ViewPlanning";
@import "ViewSchema";
@import "ViewTransfer";
@import "ViewWeekLog";
@import "ReactTags";
