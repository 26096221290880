.week-supply {
  background-color: $color-gray-2;
  border-radius: $border-radius-05;

  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin: 4px;
  padding: 4px;

  span {
    display: flex;
    flex: 1;
    line-height: 28px;
    padding-left: 4px;
    padding-right: 4px;
  }

  button.info {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: $color-gray-8;
    display: inline-block;

    transition: .3s ease color;

    &:hover {
      color: rgba($color-gray-8, 0.5);
    }

    .material-icons {
      font-size: 16px;
    }
  }

  button.transfer {
    background-color: $color-white;
    height: 28px;
    border: none;
    border-radius: $border-radius-025;
    cursor: pointer;

    transition: .3s ease all;

    &:hover {
      background-color: $color-gray-1;
      cursor: pointer;
    }

    &:disabled {
      background-color: $color-white;
      cursor: auto;
    }
  }
}
