.week-costs {
  background-color: $color-gray-2;
  border-radius: $border-radius-05;

  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin: 4px;
  padding: 4px;

  span {
    display: flex;
    flex: 1;
    line-height: 28px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
