.room-overview {
  padding: $gutter-1;

  .room-overview-header {
    font-size: 14px;
    margin-bottom: $gutter-025;

    .col-auto {
      padding: 0px;
    }
  }

  .room-overview-content {
    background-color: $color-gray-1;
    border-radius: $border-radius-075;
    background-color: $color-gray-1;
    cursor: pointer;

    .room-overview-beds {
      min-height: 180px;
    }
  }

  .room-overview-footer {
    .room-overview-notes {
      font-size: 12px;

      .icon {
        margin-top: 0px;

        > button.btn {
          padding-top: 9px;
          padding-bottom: 9px;
          padding-left: 0px;
          margin: 0;
          align-items: center;
          justify-content: center;
          flex: 0;
          display: flex;
        }
      }

      .remarks-count {
        position: absolute;
        color: $color-white;
        font-size: 11px;
        font-weight: bold;
        margin-top: -3px;
      }
    }
  }
}

.room-overview-beds {
  margin-top: $gutter-05;

  .bed-overview {
    color: $color-white;
    border-radius: $border-radius-075;
    margin-top: $gutter-05;

    &.available {
      background-color: $color-green;
      color: $color-white;
      cursor: pointer;
    }

    &.occupied {
      background-color: $color-gray-5;
      color: $color-gray-3;
    }

    &.deleted {
      opacity: 0.6;
    }

    .row {
      padding: $gutter-025 $gutter-05;
      line-height: 24px;
      margin-left: 0;

      .col-auto {
        padding-left: 0;
      }

      .col-flex {
        display: flex;
      }

      .col-icon {
        display: flex;
        flex: 0 0 20px;
        align-items: center;

        .material-icons {
          font-size: 20px;
        }
      }

      .col-status {
        font-size: 13px;
        margin-left: 4px;
      }

      .col-reservation-span {
        font-size: 11px;
        margin-right: 8px;
      }

      .col-context {
        margin-right: 8px;
      }

      .col-flex-info {
        padding-right: 8px;
      }

      .col-info {
        font-size: 11px;

        padding-left: 6px;
        padding-right: 6px;

        &.bed-state-changing {
          background-color: #d61f6e;
          border-radius: $border-radius-05;
        }
      }
    }

    .row-space-between {
      justify-content: space-between;
    }
  }
}

.room-details {
  label .material-icons {
    font-size: 16px;
    vertical-align: middle;
    margin-bottom: 2px !important;
  }

  .room-overview-beds {
    margin: $gutter-05 0;

    .employee {
      background-color: $color-gray-2;
    }
  }
}

.room-reservations {
  .row {
    display: flex;
    align-items: center;

    .col-icon {
      display: flex;
      flex: 0 0 20px;
      padding-right: 0;
    }

    .col-employee {
      flex: 1 0 auto;
    }

    .col-date {
      flex: 0 0 140px;
      text-align: center;
      padding: 0;

      span.details {
        display: block;
        font-size: 10px;
        color: $color-gray-4;
      }
    }

    .col-context {
      flex: 0 0 38px;
      padding-left: 0;

      .btn {
        color: black !important;
      }
    }
  }

  .row-difference {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;

    font-size: 12px;
    color: $color-gray-4;
    margin-bottom: $gutter-05;

    span {
      position: relative;
      display: inline-block;
      width: auto;
    }

    span:before,
    span:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: $color-gray-3;
    }

    span:before {
      left: 100%;
      margin-left: 15px;
    }
    
    span:after {
      right: 100%;
      margin-right: 15px;
    }
  }
}