section.view-planning,
section.view-bedplanner,
section.view-housing {
  .planning-table {
    padding: 0 $gutter-1;

    .row-header {
      background-color: transparent;
      //font-size: 12px;
      font-weight: 300;
      font-size: 11px;
      text-transform: uppercase;
      padding-left: 8px;
    }
  }

  .load-more {
    margin-bottom: 16px;
  }

  .load-more .col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
