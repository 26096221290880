.hotel-picker {
  margin: $gutter-1 0;

  .col-auto {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      display: flex;
      flex: 0;
      padding-right: $gutter-05;
    }
  }

  select {
    background-color: $color-gray-1;
    border: none;
    min-width: calc(162px + 12px + 36px);

    &:disabled {
      background-color: $color-gray-1;
      opacity: 0.5;
    }
  }

  button.add {
    background-color: $color-gray-2;
    height: 28px;
    border: none;
    border-radius: $border-radius-025;
    cursor: pointer;

    transition: .3s ease all;

    margin-left: $gutter-05;

    &:hover {
      background-color: $color-gray-3;
      cursor: pointer;
    }

    span.material-icons {
      line-height: 26px;
      font-size: 16px;
      width: 32px;
      padding: 0 $gutter-05;
    }
  }
}

.modal-content .hotel-picker {
  select {
    background-color: $color-white;
  }
}
