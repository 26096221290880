.view-login {
  padding-top: $gutter-4;

  .login-card {
    padding: $gutter-1;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-05;

    .login-logo {
      width: 100%;
      text-align: center;
      padding: $gutter-2 0 ;
    }

    .btn-link {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
