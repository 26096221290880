.hotel-week {
  background-color: $color-gray-1;
  margin-bottom: $gutter-05;
  border-radius: $border-radius-075;
  font-size: 14px;
  font-weight: 700;

  &:not(.row-header) {
    border: 3px solid $color-gray-1;
  }

  &.highlight {
    border: 3px solid $color-green;
  }

  &.soften {
    opacity: 0.5;

    transition: .3s ease opacity;

    &:hover {
      opacity: 1.0;
    }
  }

  > div {
    padding: 0;
  }

  .col-week-nr {
    flex: 0 0 44px;

    .week-nr {
      background-color: $color-gray-5;
      width: 44px;
      height: 44px;
      display: block;

      color: $color-white;
      line-height: 44px;
      text-align: center;
      border-radius: $border-radius-05;
      text-decoration: none;

      transition: .3s ease all;

      &:not(.no-hover) {
        cursor: pointer;
      }

      &:not(.no-hover):hover {
        background-color: rgba($color-gray-5, .75);
      }
    }
  }

  .col-auto {
    flex: 1 1;
    display: flex;
    padding-left: 8px;

    .hotel-info {
      line-height: 44px;
    }

    .hotel-data {
      color: $color-gray-4;
      font-size: 12px;
      line-height: 44px;

      font-weight: normal;
      margin-left: 16px;
    }

    .material-icons {
      display: inline;
      font-size: 32px;
      margin-right: 4px;
      line-height: 44px;

      flex: 0 0 36px;
    }

    .dropdown {
      margin-left: 12px;
    }

    .dropdown .material-icons {
      display: inline;
      font-size: 24px;
      line-height: 24px;

      margin-right: 4px;

      flex: 0 0 36px;
    }
  }

  .col-capacity {
    flex: 0 0 64px;
  }

  .col-capacity-used {
    flex: 0 0 64px;
  }

  .col-capacity-data {
    flex: 0 0 128px;
    padding: 2px 0px;

    .capacity-data {
      display: flex;
      height: 100%;
      margin-right: 5px;
      padding: 4px;

      line-height: 30px;

      background-color: $color-gray-2;
      border-radius: $border-radius-05;

      .capacity-total {
        display: flex;
        flex: 1;
        justify-content: center;
        text-align: center;
      }

      .capacity-used,
      .capacity-available {
        display: flex;
        flex: 1;
        justify-content: center;
        color: $color-white;
        text-align: center;

        border-radius: $border-radius-05;
      }

      .capacity-used {
        background-color: $color-gray-4;
      }

      .capacity-available {
        background-color: $color-green;
      }

      .material-icons {
        display: inline-block;
        font-size: 16px;
        line-height: 28px;
        margin-right: 4px;
        opacity: .6;

        flex: 0 0 16px;
        align-self: center;
      }
    }
  }

  .col-total {
    flex: 0 0 96px;

    .total {
      width: 100%;
      height: 44px;
      //display: block;
      display: flex;
      flex: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      color: $color-white;
      line-height: 44px;
      text-align: center;
      border-radius: $border-radius-05;

      .material-icons {
        display: inline-block;
        font-size: 20px;
        margin-right: 4px;
        opacity: .6;
      }
    }
  }
}
