.week-forecast {
  .forecast-tabs {
    .tab-header {
      //margin-bottom: 1.5rem;
      display: flex;
      flex: 1 1;

      button {
        background-color: $color-gray-2;
        display: flex;
        flex: 1 1;
        border: 1px solid $color-gray-1;
        border-bottom: none;
        border-radius: 7.5px 7.5px 0 0;
        padding: 5px 15px;
        margin: 0 3px;
        text-align: center;

        &:hover {
          background-color: $color-gray-2;
        }

        &.active {
          background-color: $color-gray-1;
          border-color: $color-gray-2;
          font-weight: bold;
          box-shadow: 0px 1px 0px $color-gray-1;
        }
      }
    }

    .tab-body {
      border: 1px solid $color-gray-2;
      padding: 15px;
    }
  }

  .forecast-wizard {
    table.table {
      background: none;
      overflow: hidden;
      z-index: 2;

      tr, th, td {
        background: none;
        position: relative;
        border: none;
        text-align: center;
      }

      th {
        z-index: 1;

        &:first-child {
          width: 240px;
          text-align: left;
        }

        span.invalid {
          color: $color-red;
          font-weight: bold;
        }
      }

      td {
        span {
          z-index: 1;
          position: relative;
          line-height: 28px;

          &.invalid {
            color: $color-red;
            font-weight: bold;
          }
        }

        &:hover::before {
          background-color: $color-gray-2;
          content: '';
          height: 100%;
          left: -5000px;
          position: absolute;
          top: 0;
          width: 10000px;
          z-index: 0;
        }

        &:hover::after {
          background-color: $color-gray-2;
          content: '';
          height: 10000px;
          left: 0;
          position: absolute;
          top: -5000px;
          width: 100%;
          z-index: 0;
        }

        input.estimate {
          width: 60px;
          border: 1px solid $color-gray-6;
          border-radius: 6px;
          background: none;
          text-align: center;
          z-index: 1;
          position: relative;

          /* Chrome, Safari, Edge, Opera */
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type=number] {
            -moz-appearance: textfield;
          }
        }
      }

      tr.invalid > td > input.day-estimate,
      tr > td > input.estimate.error {
        background-color: rgba($color: $color-red, $alpha: 0.4);
      }

      tr > td > input.estimate.deviation {
        background-color: rgba($color: $color-oranger, $alpha: 0.6);
      }
    }

    .forecast-wizard-header {
      margin-bottom: .5rem;
    }

    .forecast-wizard-pager {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      justify-content: space-between;

      .pager-chevron-button {
        background: none;
        border: none;
        display: flex;
        flex: 0 0 40px;

        .material-icons {
          font-size: 32px;
        }
      }

      .pager-page-button {
        background: $color-gray-3;
        display: inline-block;
        border: 0;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        margin: 0 4px;

        &.active {
          background: $color-green;
        }
      }
    }

    .forecast-wizard-submit {
      margin-top: 1.5rem;
    }
  }
}
