.view-costs {
  .costs-overview {
    padding: 0 15px;

    small {
      display: block;
      margin-bottom: $gutter-025;
    }

    .cost-nights,
    .cost-room,
    .cost-tax,
    .cost-total {
      width: 160px;
      text-align: center;
    }

    .cost-toggle {
      width: 40px;
    }

    .row-header {
      font-weight: 300;
      font-size: 11px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .costs {
      margin: 0 0 $gutter-1;
      padding: 0;
      list-style: none;

      .cost {
        background-color: $color-gray-1;
        //padding: $gutter-05;
        margin-bottom: $gutter-05;
        border-radius: $border-radius-05;
        cursor: pointer;

        .cost-header {
          padding: 5px;
          display: flex;
          flex: 0 1;

          background-color: $color-gray-1;
          border-radius: $border-radius-05;
          -webkit-transition: .3s ease background-color;
          transition: .3s ease background-color;

          &:hover {
            background-color: $color-gray-2;
          }

        }

        .cost-info {
          .cost-path, .cost-dates {
            font-size: 12px;
          }
        }

        .cost-icon {
          margin-left: 10px;
          margin-right: 10px;
        }

        .cost-breakdown {
          display: none;
          //margin-top: 5px;
          padding: 10px;

          .breakdown {
            background-color: $color-white;
            border-radius: $border-radius-05;
            padding: 10px;

            .row-breakdown {
              &.row-header {
                font-size: 12px;
              }

              .col-auto {
                width: 160px;
              }
            }

            hr {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }

        .cost-toggle {
          button {
            border: none;
            background: none;
          }
        }

        &.in {
          .cost-header {
            background-color: $color-green;
            color: $color-white;
          }

          .cost-breakdown {
            display: block;
          }
        }
      }
    }
  }
}
