.week-demand {
  background-color: $color-gray-5;
  border-radius: $border-radius-05;

  display: flex;
  flex: 1 1;
  flex-direction: row;
  min-width: 100px;
  justify-content: space-between;
  margin: 2px 4px;
  padding: 4px;
  position: relative;
  color: $color-white;
  font-weight: bold;
  border: none;

  -webkit-transition: background-color .3s ease;
          transition: background-color .3s ease;

  &:hover {
    background-color: $color-gray-4;
  }

  > span {
    line-height: 32px;
    padding: 0 8px;
    
    &.icon {
      font-size: 16px;
      text-align: right;
      width: 30px;
    }

    &.green {
      color: $color-green-2;
    }

    &.orange {
      color: $color-orange;
    }

    &.red {
      color: $color-red-2;
    }
  }
}
