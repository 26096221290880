.task {
  background-color: $color-gray-1;
  border-radius: $border-radius-05;
  margin-bottom: $gutter-05;

  .task-header {
    padding: $gutter-05;
    display: flex;
    flex-direction: row;
    border-radius: $border-radius-05;

    cursor: pointer;

    -webkit-transition: .3s ease background-color;
    transition: .3s ease background-color;

    .task-icon {
      display: inline-block;
      width: 36px;
      height: 24px;
      text-align: center;
      margin: 0px;
    }

    .marked {
      color: $color-red;
    }

    .task-text {
      padding: 0 $gutter-025;

      span {
        display: block;
        font-size: 12px;
      }
    }

    .task-deadline {
      font-size: 14px;
    }
  }
}
