section.view-planboard {
  .horizontal-scroll {
    display: block;
    overflow-x: scroll; 
    white-space: nowrap; 

    .col-4 { 
      display: inline-block; 
      float: none; 
      vertical-align: top;
    }
  }
}

