section.view-schema {  
  small {
    font-size: 14px;
  }

  .list {
    margin-bottom: $gutter-1;
  }

  .row-list-header {
    cursor: pointer;
  }
  
  
  .employee-list .col-state-icon {
    max-width: 20px;
    line-height: 24px;
    
    .state-icon {
      &::before {
        font-family: "Material Icons";
        content: "\e5ce";
        font-size: 20px;
      }
    }
  }

  .employee-list.collapsed {
    .col-state-icon {
      .state-icon::before {
        font-family: "Material Icons";
        content: "\e5cf";
      }
    }
    
    .row {
      margin-bottom: $gutter-1;
    }

    .list {
      display: none;
    }
  }
}