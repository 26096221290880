.view-transfer {
  .row-employee {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;

    .employee {
      background-color: #E4E4E4;
      flex: 1;
      //display: flex;
    }

    .transfer-button {
      background-color: $color-white;
      display: flex;
      flex: 0 0 60px;
      width: 60px;
      margin-left: 6px;
      border: none;
      border-radius: $border-radius-05;
      cursor: pointer;
      font-weight: 700;
      transition: .3s ease all;
      margin-bottom: $gutter-05;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $color-green;
        cursor: pointer;
        color: white;
      }
    }

    button.transferred {
      background-color: $color-red;
      color: $color-white;

      &:hover {
        background-color: $color-red-2;
        cursor: pointer;
      }
    }
  }

  .search-results {
    overflow-y: scroll;
    max-height: 500px;
  }
}
