.week-temps {
  background-color: $color-gray-2;
  border-radius: $border-radius-05;

  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin: 4px;
  padding: 4px;
  min-height: 36px;

  .agencies {
    display: flex;
    flex: 1;
    line-height: 28px;

    .agency {
      background-color: $color-gray-3;
      border-radius: $border-radius-05;
      display: inline-block;
      margin-right: 4px;
      
      .agency-name {
        background-color: rgba($color-gray-4, 0.3);
        border-radius: $border-radius-05;

        width: 36px;
        display: inline-block;
        padding: 0 6px 0 4px;
        
        text-align: center;

        abbr {
          text-decoration: none;
        }
      }

      .agency-supply {
        display: inline-block;
        padding: 0 4px;
        min-width: 36px;
        text-align: center;
      }
    }
  }

  button.add {
    background-color: $color-white;
    height: 28px;
    border: none;
    border-radius: $border-radius-025;
    cursor: pointer;

    transition: .3s ease all;

    &:hover {
      background-color: $color-gray-1;
      cursor: pointer;
    }

    &:disabled {
      background-color: $color-white;
      cursor: auto;
    }

    span.material-icons {
      line-height: 26px;
      font-size: 16px;
      width: 32px;
      padding: 0 4px;
    }
  }
}
