.view-check-in-out, .view-planboard {
  small {
    display: block;
    margin-bottom: $gutter-025;
  }

  .reservations, .employees {
    margin: 0 0 $gutter-1;
    padding: 0;
    list-style: none;

    .reservation {
      background-color: $color-gray-1;
      padding: $gutter-05;
      margin-bottom: $gutter-05;
      border-radius: $border-radius-05;
      display: flex;
      align-items: center;

      -webkit-transition: .3s ease background-color;
      transition: .3s ease background-color;

      .reservation-icon {
        margin-right: $gutter-05;
      }

      .reservation-info {
        .reservation-path, .reservation-dates {
          font-size: 12px;
        }
      }

      &:hover {
        background-color: $color-gray-2;
      }
    }
  }

  button.btn-plan {
    background-color: $color-white;
    color: $color-black;
    margin-left: $gutter-1;
    line-height: 100%;
    padding: $gutter-025 $gutter-05;

    &:hover {
      background-color: $color-gray-1;
    }
  }
}
