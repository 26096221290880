section.view-bedplanner {
  .bedplanner-overview {
    padding: 0 $gutter-05;
  }

  .room-add {
    height: calc(100% - (1rem + 21px + 4px) - (1rem));
    min-height: 232px;

    margin: calc(1rem + 21px + 4px) 4px 1rem;

    border-radius: $border-radius-075;
    border: 4px dashed $color-gray-1;

    display: flex;
    align-items:center;
    justify-content:center;

    cursor: pointer;
    transition: background-color 0.3s ease;

    > p {
      color: $color-gray-5;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .room-add:hover {
    background-color: $color-gray-1;
  }

  .btn-plan {
    background: $color-gray-5;
    color: $color-white;

    &:hover {
      background: $color-gray-4;
    }
  }
}

