section.view-housing {
  .housing-overview {
    padding: 0 $gutter-05;
  }
}

.hotel-room-remarks {
  margin-top: $gutter-025;
  padding-right: $gutter-05;

  .row-remark {
    padding-top: $gutter-01;
    padding-bottom: $gutter-01;
  }

  .col-remark {
    flex: 1 1;
    font-size: 13px;

    .remark-user {
      font-weight: 700;
    }
  }

  .col-edit, .col-delete {
    flex: 0 0 20px;
    cursor: pointer;
    line-height: 10px;
    padding-left: 0px;
    padding-top: 2px;
    padding-right: 0px;
    
    > span.material-icons {
      font-size: 16px;
    }
  }

  .col-date {
    flex: 0 0 120px;
    padding-left: 0px;

    font-size: 13px;
    color: $color-gray-4;
    text-align: right;
  }
}