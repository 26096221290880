.dropdown {
  > button.btn {
    padding-top: 9px;
    padding-bottom: 9px;
    margin: 0;
    align-items: center;
    justify-content: center;
    flex: 0;
    display: flex;
  }

  .dropdown-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
  }

  .dropdown-menu {
    right: 0;
    border: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    z-index: 910;

    &.in {
      display: block;
    }

    &.dropdown-gutter {
      padding: 6px;

      .react-calendar {
        width: 300px;
        border: none;
      }
    }
  }
}
