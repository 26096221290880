.employee {
  background-color: $color-gray-1;
  border-radius: $border-radius-05;
  margin-bottom: $gutter-05;

  .employee-header {
    padding: $gutter-05;
    display: flex;
    flex-direction: row;
    border-radius: $border-radius-05;

    cursor: pointer;

    -webkit-transition: .3s ease background-color;
    transition: .3s ease background-color;

    .employee-icon {
      display: inline-block;
      width: 36px;
      height: 24px;
      text-align: center;
      margin: 0px;

      .agency-name {
        background-color: rgba($color-gray-4, 0.3);
        border-radius: $border-radius-05;

        width: 36px;
        line-height: 28px;
        display: inline-block;

        text-align: center;
        font-weight: 700;
        font-size: 14px;
        margin-top: -4px;

        abbr {
          text-decoration: none;
        }
      }

      .marked {
        color: $color-red;
      }

      .marked-green {
        color: $color-green-2;
      }
    }

    .employee-availability {
      width: 68px;
      display: flex;
      flex-direction: row;
      gap: 2px;

      .day {
        width: 8px;
        height: 16px;
      }

      .day-available {
        background-color: $color-green-2;
      }

      .day-unavailable {
        background-color: $color-red;
      }

      .day-weekend {
        background-color: $color-gray-4;
      }
    }

    .employee-name {
      padding: 0 $gutter-025;
      //line-height: 26px;

      span {
        display: block;
        font-size: 12px;
      }
    }
  }

  .employee-body {
    display: none;
    font-size: 14px;
    padding: $gutter-05;

    .employee-area {
      display: flex;
      flex-direction: row;

      img {
        width: 32px;
        margin-right: $gutter-05;
      }
    }

    .employee-absences {
      background-color: $color-white;
      border-radius: $border-radius-05;
      padding: $gutter-05;
      margin-top: $gutter-05;

      hr {
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .row-absence {
        padding-top: $gutter-01;
        padding-bottom: $gutter-01;
      }

      .col-dossier {
        flex: 1 1;
        font-size: 13px;
        font-weight: 700;
      }

      .col-date {
        flex: 0 0 240px;
        font-size: 13px;
        color: $color-gray-4;
        text-align: right;
      }
    }
  }

  .employee-remarks {
    margin-top: $gutter-025;
    padding-left: $gutter-05;
    padding-right: $gutter-05;

    .row-remark {
      padding-top: $gutter-01;
      padding-bottom: $gutter-01;
    }

    .col-remark {
      flex: 1 1;
      font-size: 13px;

      .remark-user {
        font-weight: 700;
      }
    }

    .col-edit, .col-delete {
      flex: 0 0 20px;
      cursor: pointer;
      line-height: 10px;
      padding-left: 0px;
      padding-top: 2px;
      padding-right: 0px;
      
      > span.material-icons {
        font-size: 16px;
      }
    }

    .col-date {
      flex: 0 0 110px;
      padding-left: 0px;

      font-size: 13px;
      color: $color-gray-4;
      text-align: right;
    }
  }

  .employee-context {
    .btn {
      padding: 0;
      margin: 0;
    }
  }

  &.in {
    .employee-header {
      background-color: $color-green;
      color: $color-white;
    }

    .employee-body {
      display: block;
    }

    .employee-context .btn {
      color: $color-white;
    }
  }

  &:hover:not(.in):not(.employee-search) {
    .employee-header {
      background-color: $color-gray-2;
    }
  }

  &.employee-search {
    background-color: $color-white;

    .employee-header {
      cursor: default;
    }
  }
}
