.date-picker {
  margin: $gutter-1 0;

  .col-auto {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      display: flex;
      flex: 0;
      padding-right: $gutter-05;
    }
  }

  .btn {
    background-color: $color-gray-1;
    border: none;
    min-width: calc(162px + 12px + 36px);

    padding-top: 6px !important;
    padding-bottom: 6px !important;

    justify-content: left !important;
    
    span {
      flex: auto;
      text-align: right;
    }

    &:disabled {
      background-color: $color-gray-1;
      opacity: 0.5;
    }
  }
}
