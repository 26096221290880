aside {
  width: $aside-width;
  height: 100%;

  position: fixed;
  left: -$aside-width;
  top: 0;

  display: flex;
  flex-direction: column;
  flex: 1;

  &.in {
    left: 0;
  }

  box-shadow: 10px 0 25px rgba(0, 0, 0, 0.15);

  .logo {
    display: flex;
    flex: 0 0;
    width: $aside-width;
    height: 96px;
    justify-content: center;
    align-items: center;
    padding: 32px 0 20px 0;
  }

  ul {
    margin: 0;
    padding: 15px;
    list-style: none;
    display: flex;
    flex: 1;
    flex-direction: column;


    li {
      display: flex;
      flex: 0;
      flex-flow: column nowrap;

      a {
        width: 100%;
        height: 48px;
        margin-bottom: 7.5px;
        padding: 0 $gutter-1;

        color: $color-black;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        line-height: 48px;

        cursor: pointer;
        display: block;

        border-radius: 6px;

        background-color: transparent;

        transition: .3s ease color, .3s ease background-color;

        span {
          float: left;
          padding: 11px 10px 0 0;
        }

        &.active {
          background-color: $color-green;
          color: $color-white;
        }

        &:hover {
          background-color: rgba($color-green, 0.5);
          color: $color-white;
        }
      }

      hr {
        background-color: $color-gray-4;
      }

      &.move-down {
        justify-self: flex-end;
        margin-top: auto;
      }
    }
  }
}
