.week-budget-container {

  .grey-out {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    z-index: 1;
    display: none;
  }

  &.altered {
    .grey-out {
      display: block;
    }
  }
}

.week-budget {
  background-color: $color-gray-5;
  border-radius: $border-radius-05;

  display: flex;
  flex: 1 1;
  flex-direction: row;
  min-width: 70px;
  justify-content: space-between;
  margin: 2px 4px;
  padding: 4px;
  position: relative;

  color: $color-white;

  &.editable {
    min-width: 130px;
    cursor: pointer;
  }

  > span {
    line-height: 32px;
    flex: 1;
    padding: 0 8px;
  }

  > input {
    width: 100%;
    flex: 1;
    padding: 0 8px;
    font-weight: 700;
    line-height: 32px;

    border: none;
    border-radius: 3px;
  }

  > input[disabled] {
    background-color: transparent;
    color: white;
    overflow-x: visible;
  }

  button[disabled] {
    opacity: 0.25;
  }

  button.info {
    background: none;
    border: none;
    height: 28px;
    margin: 0 4px;
    padding-top: 6px;
    color: $color-gray-1;
    display: inline-block;

    transition: .3s ease color;

    &:hover {
      color: rgba($color-gray-1, 0.5);
    }

    .material-icons {
      font-size: 16px;
    }
  }

  button.alter {
    background-color: $color-gray-4;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: $border-radius-025;
    cursor: pointer;
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 6px 4px;

    transition: .3s ease all;

    &:hover {
      background-color: $color-gray-1;
      cursor: pointer;
      color: $color-black;
    }

    &:disabled {
      background-color: $color-gray-4;
      color: $color-gray-3;
      cursor: auto;
    }

    .material-icons {
      font-size: 18px;
    }
  }

  &.altered {
    background-color: $color-green;
    z-index: 999;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    button.alter {
      background-color: $color-green-2;
    }

    button.info {
      display: none;
    }

    input {
      text-align: center;
      margin-left: 3px;
      margin-right: 3px;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
  }

  .budget-alter {
    z-index: 998;
    background-color: $color-green;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0 4px 4px 4px;
    margin-left: -4px;
    border-bottom-left-radius: $border-radius-05;
    border-bottom-right-radius: $border-radius-05;

    button.alter {
      background-color: $color-white;
      width: 100%;
      height: 32px;
      text-align: center;
      border: none;
      font-size: 12px;
      font-weight: 600;
      color: $color-black;
      border-radius: $border-radius-025;
    }
  }
}