.week {
  background-color: $color-gray-1;
  margin-bottom: $gutter-05;
  border-radius: $border-radius-075;
  font-size: 14px;
  font-weight: 700;

  &:not(.row-header) {
    border: 3px solid $color-gray-1;
  }

  &.highlight {
    border: 3px solid $color-green;
  }

  &.soften {
    opacity: 0.5;

    transition: .3s ease opacity;

    &:hover {
      opacity: 1.0;
    }
  }

  >div {
    padding: 0;
  }

  .col-week-nr {
    flex: 0 0 44px;

    .week-nr {
      background-color: $color-gray-5;
      width: 44px;
      height: 44px;
      display: block;

      color: $color-white;
      line-height: 44px;
      text-align: center;
      border-radius: $border-radius-05;
      text-decoration: none;

      transition: .3s ease all;

      &:not(.no-hover):hover {
        background-color: rgba($color-gray-5, .75);
      }
    }
  }

  .col-budget {
    flex: 0 0 70px;
  }

  .col-budget-editable {
    flex: 0 0 138px;
  }

  .col-demand {
    flex: 0 0 108px;
  }

  .col-costs {
    flex: 0 0 120px;
  }

  .col-supply {
    flex: 0 0 92px;
  }

  .col-supply.with-supply-transfer {
    flex: 0 0 135px;
  }

  .col-fte {
    flex: 0 0 88px;
    padding-right: 8px;

    text-align: right;
    font-weight: normal;
    font-size: 12px;
    line-height: 44px;
  }

  .col-temps {
    flex: 1 1;
  }

  .col-remarks {
    flex: 0 0 48px;

    .icon {
      margin-top: 0;

      >button.btn {
        padding-top: 9px;
        padding-bottom: 9px;
        margin: 0;
        align-items: center;
        justify-content: center;
        flex: 0;
        display: flex;
      }
    }

    .remarks-count {
      position: absolute;
      color: $color-white;
      font-size: 11px;
      font-weight: bold;
      margin-top: -3px;
    }
  }

  .col-context {
    flex: 0 0 64px;

    .lock {
      padding-top: 9px;
      padding-left: 12px;
      text-align: center;
      cursor: pointer;
      color: $color-green-2;
    }
  }

  .col-total {
    flex: 0 0 96px;

    .total {
      width: 100%;
      height: 44px;
      display: block;

      color: $color-white;
      line-height: 44px;
      text-align: center;
      border-radius: $border-radius-05;
    }
  }

  .col-location {
    display: flex;
    flex: 0 0 310px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;

    img {
      width: 30px;
      margin: 0 $gutter-1;
    }

    h1 {
      margin-right: $gutter-1;
    }

    h2 {
      font-size: 12px;
      margin-right: $gutter-1;
    }
  }

  &.week-large {
    .col-label {
      padding: 8px 8px 0 8px;
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
    }

    .col-label-right {
      float: right;
    }

    .col-week-nr {
      flex: 0 0 80px;

      .week-nr {
        width: 80px;
        height: 80px;

        font-size: 36px;

        span {
          display: block;
          font-size: 12px;
          line-height: 1;
          padding-top: 12px;
        }
      }
    }

    .col-fte {
      display: none;
    }

    .col-total {
      .total {
        height: 80px;
        line-height: 80px;
        font-size: 24px;
      }
    }

    .col-remarks {
      .icon {
        margin-top: 20px;
      }
    }

    .col-context {
      .dropdown {
        margin-top: 20px;
      }

      .lock {
        padding-top: 28px;
      }
    }
  }
}