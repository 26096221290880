.view-weeklog {
  .weeklog-table {
    padding: 0 $gutter-1;

    .row-weeklog {
      background-color: $color-gray-2;
      margin-bottom: $gutter-05;
      border-radius: $border-radius-075;
      font-size: 14px;
      font-weight: 300;
      padding-top: 3px;
      padding-bottom: 3px;

      > div {
        padding: 0;
      }

      .col-hours {
        flex: 0 0 108px;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }

      .col-name {
        flex: 0 0 108px;
        font-size: 12px;
        font-weight: 700;
        //padding-left: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
      }

      .col-comment {
        display: flex;
        flex: 1 1;
        font-size: 12px;
        font-weight: 700;
        color: $color-gray-4;
        padding-top: 6px;
        padding-bottom: 6px;
        //line-height: 24px;
        flex-direction: row;

        span {
          display: flex;
          flex: 0 0;
          white-space: nowrap;

          &.material-icons {
            color: $color-green-2;
            padding-left: 4px;
            font-size: 16px;
            //line
          }
        }
      }

      .col-edit, .col-delete {
        flex: 0 0 24px;
        padding-top: 5px;
        cursor: pointer;

        > span.material-icons {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .col-date {
        flex: 0 0 110px;
        font-size: 12px;
        font-weight: 700;
        padding-top: 6px;
        padding-bottom: 6px;
      }

      &.row-weeklog-current {
        background-color: $color-gray-5;
        color: $color-white;

        .col-comment {
          color: $color-gray-3;
        }
      }

      &.row-weeklog-current-supply {
        background-color: $color-green;

        .col-comment {
          color: $color-gray-1;
        }
      }

      &.row-weeklog-current-demand {
        background-color: $color-red;

        .col-comment {
          color: $color-gray-1;
        }
      }
    }

    .row-weeklock {
      display: block;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;

      font-size: 12px;
      color: $color-gray-4;
      margin-bottom: $gutter-05;

      span {
        position: relative;
        display: inline-block;
        width: auto;
      }

      span:before,
      span:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: $color-gray-3;
      }

      span:before {
        left: 100%;
        margin-right: 15px;
      }

      span:after {
        right: 100%;
        margin-left: 15px;
      }
    }
  }
}
