.modal-find-rooms {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      background-color: $color-gray-3;
      border-radius: 3px;
      padding: 5px 10px;
      margin-bottom: $gutter-05;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1;

      .room-icon {
        display: flex;
        flex: 0;
        margin-right: 10px;
      }

      .room-name {
        display: flex;
        flex: 1 1;

        strong {
          margin-right: 5px;
        }
      }

      .room-info {
        display: flex;
        flex: 0;
        white-space: nowrap;

        .pill {
          background: $color-gray-5;
          color: $color-gray-4;
          border-radius: 3px;
          padding: 0 5px;
          margin-right: 6px;

          &.pill-active {
            background: $color-green;
            color: $color-white;
          }
        }
      }

      .room-actions {
        button {
          background-color: $color-white;
          border: none;
          padding: 0 10px;
          border-radius: 6px;

          &:hover {
            background-color: $color-gray-1;
          }
        }
      }
    }
  }
}
